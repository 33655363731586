<script lang="ts">
  let className: string = "";
  export let color: string = "#9B30FF";
  export { className as class };
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  width="40"
  height="40"
  viewBox="0 0 40 40"
  fill="none"
  class={className}>
  <mask
    id="mask0_5856_14264"
    style="mask-type:alpha"
    maskUnits="userSpaceOnUse"
    x="0"
    y="0"
    width="40"
    height="40">
    <rect width="40" height="40" fill="#D9D9D9" />
  </mask>
  <g mask="url(#mask0_5856_14264)">
    <path
      d="M12.3346 32.6663L8.66797 28.958L20.0013 17.6247L31.3346 28.958L27.668 32.6663L20.0013 24.9997L12.3346 32.6663ZM12.3346 21.3747L8.66797 17.708L20.0013 6.33301L31.3346 17.708L27.668 21.3747L20.0013 13.708L12.3346 21.3747Z"
      fill={color} />
  </g>
</svg>
