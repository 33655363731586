<script lang="ts">
  // lib imports
  import Image from "@/components/ui/image/image.svelte";
  import Typography from "@/components/ui/typography/typography.svelte";
  import Autoplay from "embla-carousel-autoplay";
  import emblaCarouselSvelte from "embla-carousel-svelte";
  // icons & images imports
  import AccountIcon from "@/components/icons/account-icon.svelte";
  import DoubleArrowIcon from "@/components/icons/doubel-arrow-up.svelte";
  import CheckIcon from "@/images/check.svg";
  import ChoRounded from "@/images/cho_rounded.png?raw";
  import CS2ICON from "@/images/cs2.svg";
  import GroupsIcon from "@/images/groups.svg";
  import GwenRounded from "@/images/gwen_rounded.png?raw";
  import HeimerdingerRounded from "@/images/heimerdinger_rounded.png?raw";
  import JaxRounded from "@/images/jax_rounded.png?raw";
  import DoubleArrowRightIcon from "@/images/keyboard_double_arrow_right.svg";
  import LeagueIcon from "@/images/lol.svg";
  import ProgressBlue from "@/images/progess_blue.png?raw";
  import ProgressAccent from "@/images/progress_accent.png?raw";
  import ValorantIcon from "@/images/val.svg";

  // variables
  let options = { loop: true };
  let plugins = [Autoplay({ delay: 1500 })];

  // functions
</script>

<div
  class="mb-8 flex w-full flex-col items-center justify-center px-6 py-8 text-center md:mb-14 md:py-20">
  <!-- title -->
  <div
    class="mb-8 inline-flex min-h-[136px] flex-col items-center justify-start gap-4 md:mb-14">
    <Typography variant="h2">Our Services</Typography>
    <Typography color="muted" variant="h4">
      Discover our additional services designed to help you achieve <br /> your goals
      and take your experience to the next level.
    </Typography>
  </div>
  <div class="flex flex-col gap-4 md:flex-row">
    <!-- accounts banner -->
    <div
      class="mb-6 grid h-[752px] w-full grid-cols-2 gap-y-6 overflow-x-hidden rounded-3xl border border-dark-gray py-8 md:max-w-[min(310px,100vw-24px)]"
      style="background: linear-gradient(90deg, rgba(18, 18, 18, 0.03) 40.71%, rgba(155, 48, 255, 0.03) 100%);">
      <div class="col-span-2 flex flex-col gap-4 px-8 md:col-span-2">
        <div class="flex gap-3">
          <div
            class="flex h-[56px] w-[56px] items-center justify-center rounded-xl border border-dark-gray">
            <Image src={LeagueIcon} class="h-6 w-6" />
          </div>
          <div
            class="flex h-[56px] w-[56px] items-center justify-center rounded-xl border border-dark-gray">
            <AccountIcon class="h-6 w-6" />
          </div>
        </div>
        <div class="flex flex-col items-start gap-2">
          <Typography variant="h4" class="text-left"
            >League Of Legends Smurf Accounts</Typography>
          <Typography
            variant="large"
            color="muted"
            class="text-left !font-normal"
            >Grab a fresh League of Legends unranked account and start smurfing
            again!</Typography>
        </div>
      </div>
      <div
        use:emblaCarouselSvelte={{ options, plugins }}
        class="embla col-span-2 overflow-x-hidden md:col-span-2">
        <div class="embla__container flex h-full flex-1 gap-4">
          <!-- lvl 45 account box -->
          <div
            class="embla__slide flex min-w-[max(50%,200px)] flex-col gap-6 rounded-2xl border border-dark-gray p-6">
            <div class="flex items-center gap-3">
              <div
                class="flex min-h-[56px] min-w-[56px] items-center justify-center rounded-2xl border border-dark-gray">
                <AccountIcon color="#2B6BE9" class="h-8 w-8" />
              </div>
              <div
                class="flex h-10 min-w-[97px] items-center justify-center rounded-[40px] border border-dark-gray px-4 py-2">
                <Typography
                  variant="large"
                  class="break-none max-h-[20px] whitespace-nowrap"
                  >Level 45</Typography>
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <div class="flex items-center gap-3">
                <Image src={CheckIcon} class="h-4 w-4" />
                <Typography color="muted" variant="small" class="!font-normal"
                  >70K BE</Typography>
              </div>
              <div class="flex items-center gap-3">
                <Image src={CheckIcon} class="h-4 w-4" />
                <Typography color="muted" variant="small" class="!font-normal"
                  >Level 2 Honor</Typography>
              </div>
              <div class="flex items-center gap-3">
                <Image src={CheckIcon} class="h-4 w-4" />
                <Typography color="muted" variant="small" class="!font-normal"
                  >Lifetime Warranty</Typography>
              </div>
              <div class="flex items-center gap-3">
                <Image src={CheckIcon} class="h-4 w-4" />
                <Typography color="muted" variant="small" class="!font-normal"
                  >Full Email access</Typography>
              </div>
              <div class="flex items-center gap-3">
                <Image src={CheckIcon} class="h-4 w-4" />
                <Typography color="muted" variant="small" class="!font-normal"
                  >Instant Delivery
                </Typography>
              </div>
            </div>
          </div>
          <!-- lvl 60 account box -->
          <div
            class="embla__slide flex min-w-[max(50%,200px)] flex-col gap-6 rounded-2xl border border-accent p-6"
            style="background: radial-gradient(111.05% 148.21% at 50% 100%, rgba(155, 48, 255, 0.12) 0%, rgba(0, 0, 0, 0.00) 100%);">
            <div class="flex items-center gap-3">
              <div
                class="flex min-h-[56px] min-w-[56px] items-center justify-center rounded-2xl border border-dark-gray">
                <AccountIcon color="#CD2323" class="h-8 w-8" />
              </div>
              <div
                class="flex h-10 min-w-[97px] items-center justify-center rounded-[40px] border border-dark-gray px-4 py-2">
                <Typography
                  variant="large"
                  class="break-none max-h-[20px] whitespace-nowrap"
                  >Level 60</Typography>
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <div class="flex items-center gap-3">
                <Image src={CheckIcon} class="h-4 w-4" />
                <Typography color="muted" variant="small" class="!font-normal"
                  >100K BE</Typography>
              </div>
              <div class="flex items-center gap-3">
                <Image src={CheckIcon} class="h-4 w-4" />
                <Typography color="muted" variant="small" class="!font-normal"
                  >Level 2 Honor</Typography>
              </div>
              <div class="flex items-center gap-3">
                <Image src={CheckIcon} class="h-4 w-4" />
                <Typography color="muted" variant="small" class="!font-normal"
                  >Lifetime Warranty</Typography>
              </div>
              <div class="flex items-center gap-3">
                <Image src={CheckIcon} class="h-4 w-4" />
                <Typography color="muted" variant="small" class="!font-normal"
                  >Full Email access</Typography>
              </div>
              <div class="flex items-center gap-3">
                <Image src={CheckIcon} class="h-4 w-4" />
                <Typography color="muted" variant="small" class="!font-normal"
                  >Instant Delivery
                </Typography>
              </div>
            </div>
          </div>
          <!-- lvl 30 account box -->
          <div
            class="embla__slide mr-4 flex min-w-[max(50%,200px)] flex-col gap-6 rounded-2xl border border-dark-gray p-6">
            <div class="flex items-center gap-3">
              <div
                class="flex min-h-[56px] min-w-[56px] items-center justify-center rounded-2xl border border-dark-gray">
                <AccountIcon color="#24B432" class="h-8 w-8" />
              </div>
              <div
                class="flex h-10 min-w-[97px] items-center justify-center rounded-[40px] border border-dark-gray px-4 py-2">
                <Typography
                  variant="large"
                  class="break-none max-h-[20px] whitespace-nowrap"
                  >Level 30</Typography>
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <div class="flex items-center gap-3">
                <Image src={CheckIcon} class="h-4 w-4" />
                <Typography color="muted" variant="small" class="!font-normal"
                  >60K BE</Typography>
              </div>
              <div class="flex items-center gap-3">
                <Image src={CheckIcon} class="h-4 w-4" />
                <Typography color="muted" variant="small" class="!font-normal"
                  >Level 2 Honor</Typography>
              </div>
              <div class="flex items-center gap-3">
                <Image src={CheckIcon} class="h-4 w-4" />
                <Typography color="muted" variant="small" class="!font-normal"
                  >Lifetime Warranty</Typography>
              </div>
              <div class="flex items-center gap-3">
                <Image src={CheckIcon} class="h-4 w-4" />
                <Typography color="muted" variant="small" class="!font-normal"
                  >Full Email access</Typography>
              </div>
              <div class="flex items-center gap-3">
                <Image src={CheckIcon} class="h-4 w-4" />
                <Typography color="muted" variant="small" class="!font-normal"
                  >Instant Delivery
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- grouped banners -->
    <div
      class="container mx-auto mb-14 grid max-w-[min(874px,100vw-24px)] grid-cols-2 gap-4 px-0">
      <!-- first column -->
      <div class="col-span-2 flex flex-col gap-4 lg:col-span-1">
        <!-- lol boosting banner -->
        <div
          class="flex h-[400px] flex-col gap-y-6 rounded-3xl border border-dark-gray p-8 md:gap-y-8"
          style="background: linear-gradient(180deg, rgba(18, 18, 18, 0.03) 0%, rgba(155, 48, 255, 0.03) 100%), #121212;">
          <div class="col-span-2 flex flex-col gap-4 md:col-span-1">
            <div class="flex gap-3">
              <div
                class="flex h-[56px] w-[56px] items-center justify-center rounded-xl border border-dark-gray">
                <Image src={LeagueIcon} class="h-6 w-6" />
              </div>
              <div
                class="flex h-[56px] w-[56px] items-center justify-center rounded-xl border border-dark-gray">
                <DoubleArrowIcon class="h-6 w-6" />
              </div>
            </div>
            <div class="flex flex-col items-start gap-2">
              <Typography variant="h4" class="text-left"
                >League Of Legends Boosting</Typography>
              <Typography
                variant="large"
                color="muted"
                class="text-left !font-normal"
                >Our team of skilled LoL Boosters delivers a top-tier boosting
                experience!</Typography>
            </div>
          </div>
          <div
            class="relative col-span-2 flex flex-col items-center gap-y-3 md:col-span-1">
            <div
              class="relative flex w-[calc(100%-32px)] items-center justify-between rounded-2xl border border-dark-gray p-4"
              style="background: radial-gradient(111.05% 148.21% at 50% 100%, rgba(255, 255, 255, 0.02) 0%, rgba(0, 0, 0, 0.00) 100%);">
              <div class="flex items-center gap-4">
                <div
                  class="flex min-h-[48px] min-w-[48px] items-center justify-center rounded-xl border border-dark-gray">
                  <img
                    alt="diamond rank"
                    src="/images/rank-icons/diamond-detailed.webp"
                    class="h-6 w-[30.32px]" />
                </div>
                <div class="flex flex-col items-start gap-1">
                  <Typography class="!font-normal" variant="large"
                    >Promoted to Diomond III</Typography>
                </div>
              </div>
              <DoubleArrowIcon class="h-8 w-8" color="#797980" />
            </div>
            <div
              class="absolute top-14 flex w-full items-center justify-between rounded-2xl border border-accent bg-light-black p-4">
              <div class="flex items-center gap-4">
                <div
                  class="flex min-h-[56px] min-w-[56px] items-center justify-center rounded-xl border border-accent">
                  <img
                    alt="diamond rank"
                    src="/images/rank-icons/master-detailed.webp"
                    class="h-6 w-[30.32px]" />
                </div>
                <div class="flex flex-col items-start gap-1">
                  <Typography class="!font-normal" variant="large"
                    >Promoted to Master</Typography>
                  <Typography class="!font-normal" variant="small" color="muted"
                    >You earned 870 coins
                  </Typography>
                </div>
              </div>
              <DoubleArrowIcon class="h-10 w-10" />
            </div>
          </div>
        </div>
        <!-- cs2 boosting banner -->
        <div
          class="mb-6 flex h-[336px] flex-col justify-between gap-y-8 overflow-hidden rounded-3xl border border-dark-gray py-8"
          style="background: linear-gradient(180deg, rgba(18, 18, 18, 0.03) 0%, rgba(155, 48, 255, 0.03) 100%), #121212;">
          <div class="col-span-2 flex flex-col gap-4 px-8 md:col-span-1">
            <div class="flex gap-3">
              <div
                class="flex h-[56px] w-[56px] items-center justify-center rounded-2xl border border-dark-gray">
                <Image src={CS2ICON} class="h-6 w-6" />
              </div>
              <div
                class="flex h-[56px] w-[56px] items-center justify-center rounded-2xl border border-dark-gray">
                <DoubleArrowIcon class="h-6 w-6" />
              </div>
            </div>
            <div class="flex flex-col items-start gap-2">
              <Typography variant="h4" class="text-left"
                >Counter Strike 2 Boosting</Typography>
              <Typography
                variant="large"
                color="muted"
                class="text-left !font-normal"
                >Climb the CS2 ranks with our professional boosting team.</Typography>
            </div>
          </div>
          <div
            class=" col-span-2 flex items-center justify-center gap-0 overflow-hidden px-8 md:col-span-1">
            <div
              class="rounded-xl bg-gradient-to-r from-dark-gray from-60% to-accent p-[1px]">
              <div
                class="flex h-14 min-w-[102.5px] items-center justify-center rounded-xl border bg-theme-black"
                style="background: radial-gradient(51.8% 88.16% at 50% 100%, rgba(255, 255, 255, 0.04) 0%, rgba(18, 18, 18, 0.04) 100%), #121212;">
                <Image src={ProgressBlue} class="h-8 w-[42px]" />
              </div>
            </div>
            <div class="h-[1px] min-w-[40px] bg-accent"></div>
            <div
              class="rounded-xl bg-gradient-to-t from-transparent via-accent to-transparent p-[1px]">
              <div
                class="flex h-14 w-20 items-center justify-center rounded-xl border-2 bg-theme-black"
                style="background: radial-gradient(51.8% 88.16% at 50% 100%, rgba(255, 255, 255, 0.04) 0%, rgba(18, 18, 18, 0.04) 100%), #121212;">
                <Image src={DoubleArrowRightIcon} class="h-10 w-10" />
              </div>
            </div>
            <div class="h-[1px] min-w-[40px] bg-accent"></div>
            <div
              class="rounded-xl bg-gradient-to-l from-dark-gray from-60% to-accent p-[1px]">
              <div
                class="flex h-14 min-w-[102.5px] items-center justify-center rounded-xl border bg-theme-black"
                style="background: radial-gradient(51.8% 88.16% at 50% 100%, rgba(255, 255, 255, 0.04) 0%, rgba(18, 18, 18, 0.04) 100%), #121212;">
                <Image src={ProgressAccent} class="h-10 w-[52.5px]" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- second column -->
      <div class="col-span-2 flex h-[752px] flex-col gap-4 lg:col-span-1">
        <!-- lol teamates banner -->
        <div
          class="rounded-3xl bg-accent p-[1px]"
          style="background: rgb(41,39,46);
          background: linear-gradient(45deg, rgba(41,39,46,1) 0%, rgba(41,39,46,1) 58%, rgba(119,45,188,1) 82%, rgba(155,48,255,1) 100%);">
          <div
            class="flex flex-col gap-y-8 overflow-hidden rounded-3xl border border-dark-gray py-8"
            style="background: linear-gradient(180deg, rgba(18, 18, 18, 0.03) 0%, rgba(155, 48, 255, 0.03) 100%), #121212;">
            <div class="col-span-2 flex flex-col gap-4 px-8 md:col-span-1">
              <div class="flex w-full items-center justify-between">
                <div class="flex gap-3">
                  <div
                    class="flex h-[56px] w-[56px] items-center justify-center rounded-2xl border border-dark-gray">
                    <Image src={LeagueIcon} class="h-6 w-6" />
                  </div>
                  <div
                    class="flex h-[56px] w-[56px] items-center justify-center rounded-2xl border border-dark-gray">
                    <Image src={GroupsIcon} class="h-6 w-6" />
                  </div>
                </div>
                <div
                  class="h-fit w-fit rounded-[40px] bg-gradient-to-r from-dark-gray from-20% to-accent p-[1px]">
                  <div class="min-w-[68px] rounded-[40px] bg-theme-black p-2">
                    <Typography class="!font-normal text-accent "
                      >Soon</Typography>
                  </div>
                </div>
              </div>
              <div class="flex flex-col items-start gap-2">
                <Typography variant="h4" class="text-left"
                  >League Of Legends Teammates</Typography>
                <Typography
                  variant="large"
                  color="muted"
                  class="text-left !font-normal"
                  >Find stress-free teammates anytime for a seamless, enjoyable
                  gaming experience!</Typography>
              </div>
            </div>
            <div
              class=" col-span-2 flex flex-col items-center gap-y-2 md:col-span-1">
              <!-- row 1 -->
              <div class="flex w-full justify-center gap-2">
                <!-- player 1 -->
                <div
                  class="flex min-w-[224px] items-center justify-between gap-2 rounded-xl border border-dark-gray p-2">
                  <div class="flex items-center gap-2">
                    <Image
                      src={ChoRounded}
                      class="h-10 w-10 rounded-full border border-dark-gray" />
                    <Typography
                      variant="large"
                      color="light_gray"
                      class="!font-normal">Laverne</Typography>
                  </div>
                  <img
                    alt="diamond rank"
                    src="/images/rank-icons/challenger-detailed.webp"
                    class="h-5" />
                </div>
                <!-- player 2 -->
                <div
                  class="flex min-w-[224px] items-center justify-between gap-2 rounded-xl border border-dark-gray p-2">
                  <div class="flex items-center gap-2">
                    <Image
                      src={ChoRounded}
                      class="h-10 w-10 rounded-full border border-dark-gray" />
                    <Typography
                      variant="large"
                      color="light_gray"
                      class="!font-normal">Laverne</Typography>
                  </div>
                  <img
                    alt="diamond rank"
                    src="/images/rank-icons/grandmaster-detailed.webp"
                    class="h-5" />
                </div>
                <!-- player 3 -->
                <div
                  class="flex min-w-[224px] items-center justify-between gap-2 rounded-xl border border-dark-gray p-2">
                  <div class="flex items-center gap-2">
                    <Image
                      src={GwenRounded}
                      class="h-10 w-10 rounded-full border border-dark-gray" />
                    <Typography
                      variant="large"
                      color="light_gray"
                      class="!font-normal">Katrina</Typography>
                  </div>
                  <img
                    alt="diamond rank"
                    src="/images/rank-icons/grandmaster-detailed.webp"
                    class="h-5" />
                </div>
              </div>
              <!-- row 2 -->
              <div class="flex w-full justify-center gap-2 pr-8">
                <!-- player 4 -->
                <div
                  class="flex min-w-[224px] items-center justify-between gap-2 rounded-xl border border-dark-gray p-2">
                  <div class="flex items-center gap-2">
                    <Image
                      src={ChoRounded}
                      class="h-10 w-10 rounded-full border border-dark-gray" />
                    <Typography
                      variant="large"
                      color="light_gray"
                      class="!font-normal">Laverne</Typography>
                  </div>
                  <img
                    alt="diamond rank"
                    src="/images/rank-icons/master-detailed.webp"
                    class="h-5" />
                </div>
                <!-- player 5 -->
                <div
                  class="flex min-w-[224px] items-center justify-between gap-2 rounded-xl border border-dark-gray p-2">
                  <div class="flex items-center gap-2">
                    <Image
                      src={JaxRounded}
                      class="h-10 w-10 rounded-full border border-dark-gray" />
                    <Typography
                      variant="large"
                      color="light_gray"
                      class="!font-normal">Garnet</Typography>
                  </div>
                  <img
                    alt="diamond rank"
                    src="/images/rank-icons/challenger-detailed.webp"
                    class="h-5" />
                </div>
                <!-- player 6 -->
                <div
                  class="flex min-w-[224px] items-center justify-between gap-2 rounded-xl border border-dark-gray p-2">
                  <div class="flex items-center gap-2">
                    <Image
                      src={HeimerdingerRounded}
                      class="h-10 w-10 rounded-full border border-dark-gray" />
                    <Typography
                      variant="large"
                      color="light_gray"
                      class="!font-normal">Addie</Typography>
                  </div>
                  <img
                    alt="diamond rank"
                    src="/images/rank-icons/grandmaster-detailed.webp"
                    class="h-5" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Valorant boosting -->
        <div
          class="flex flex-col gap-y-8 overflow-hidden rounded-3xl border border-dark-gray p-8"
          style="background: linear-gradient(180deg, rgba(18, 18, 18, 0.03) 0%, rgba(155, 48, 255, 0.03) 100%), #121212;">
          <div class="col-span-2 flex flex-col gap-4 md:col-span-1">
            <div class="flex w-full items-center justify-between">
              <div class="flex gap-3">
                <div
                  class="flex h-[56px] w-[56px] items-center justify-center rounded-2xl border border-dark-gray">
                  <Image src={ValorantIcon} class="h-6 w-6" />
                </div>
                <div
                  class="flex h-[56px] w-[56px] items-center justify-center rounded-2xl border border-dark-gray">
                  <DoubleArrowIcon class="h-6 w-6" />
                </div>
              </div>
            </div>
            <div class="flex flex-col items-start gap-2">
              <Typography variant="h4" class="text-left"
                >Valorant Boosting</Typography>
              <Typography
                variant="large"
                color="muted"
                class="text-left !font-normal"
                >Boost your Valorant rank fast with an experienced booster, and
                enjoy the thrill of climbing to the top!</Typography>
            </div>
          </div>
          <div class="flex flex-col gap-3">
            <div class="flex min-w-[508px] items-center justify-start gap-3">
              <div
                class="flex h-[48px] w-[82.25px] items-center justify-center rounded-xl border border-dark-gray"
                style="background: radial-gradient(51.8% 88.16% at 50% 100%, rgba(255, 255, 255, 0.04) 0%, rgba(18, 18, 18, 0.04) 100%);">
                <img
                  alt="val rank"
                  src="/images/valorant/iron.png"
                  class="h-6 w-6" />
              </div>
              <div
                class="flex h-[48px] w-[82.25px] items-center justify-center rounded-xl border border-dark-gray"
                style="background: radial-gradient(51.8% 88.16% at 50% 100%, rgba(255, 255, 255, 0.04) 0%, rgba(18, 18, 18, 0.04) 100%);">
                <img
                  alt="val rank"
                  src="/images/valorant/bronze.png"
                  class="h-6 w-6" />
              </div>
              <div
                class="flex h-[48px] w-[82.25px] items-center justify-center rounded-xl border border-dark-gray"
                style="background: radial-gradient(51.8% 88.16% at 50% 100%, rgba(255, 255, 255, 0.04) 0%, rgba(18, 18, 18, 0.04) 100%);">
                <img
                  alt="val rank"
                  src="/images/valorant/silver.png"
                  class="h-6 w-6" />
              </div>
              <div
                class="flex h-[48px] w-[82.25px] items-center justify-center rounded-xl border border-dark-gray"
                style="background: radial-gradient(51.8% 88.16% at 50% 100%, rgba(255, 255, 255, 0.04) 0%, rgba(18, 18, 18, 0.04) 100%);">
                <img
                  alt="val rank"
                  src="/images/valorant/gold.png"
                  class="h-6 w-6" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
